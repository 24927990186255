import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loading from '../components/General/Utilities/Loading'

const Landing = lazy(() => import('../pages/Landing'))

const UnAuthenticated = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path='/*' element={<Landing />} />
            </Routes>
        </Suspense>
    )
}

export default UnAuthenticated