import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './Loading.css'

const Loading = () => {
    return (
        <div className='fixed top-0 left-0 h-screen w-screen flex items-center justify-center bg-white z-30'>
            <div className='space-y-1'>
                {/* <img src='/x.png' alt='ComX logo' className='text-center mx-auto' /> */}
                <div id='GeneralLoader'>
                    <hr /><hr /><hr /><hr />
                </div>
                {/* <h2 className='text-center'>Loading... <Spin indicator={<LoadingOutlined />} spinning={true} /> </h2> */}
                {/* <Animation /> */}
            </div>
        </div>
    )
}

export default Loading

const Animation = () => {
    return (
        <svg version='1.1' id='L4' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
            viewBox='0 0 100 100' enableBackground='new 0 0 0 0' xmlSpace='preserve'>
            <circle fill='#000' stroke='none' cx='6' cy='50' r='6'>
                <animate
                    attributeName='opacity'
                    dur='1s'
                    values='0;1;0'
                    repeatCount='indefinite'
                    begin='0.1' />
            </circle>
            <circle fill='#000' stroke='none' cx='26' cy='50' r='6'>
                <animate
                    attributeName='opacity'
                    dur='1s'
                    values='0;1;0'
                    repeatCount='indefinite'
                    begin='0.2' />
            </circle>
            <circle fill='#000' stroke='none' cx='46' cy='50' r='6'>
                <animate
                    attributeName='opacity'
                    dur='1s'
                    values='0;1;0'
                    repeatCount='indefinite'
                    begin='0.3' />
            </circle>
        </svg>
    )
}