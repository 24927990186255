export const LOCAL_STORAGE_TOKEN = "comx-admin-token";
export const LOCAL_STORAGE_DATA = "comx-admin-data";

export const Boards = {
  SPOT: {
    name: "Spot",
    code: "S",
    long_name: "Spot",
  },
  FI: {
    name: "FI",
    code: "FI",
    long_name: "FI",
  },
  OTC: {
    name: "OTC",
    code: "O",
    long_name: "OTC",
  },
  DAWA: {
    name: "Dawa",
    code: "D",
    long_name: "Deliverable",
  },
};

export const VIEWABLE_MARKETS: any = {
  O: {
    name: 'OTC'
  },
  S: {
    name: 'Spot'
  },
  D: {
    name: 'Dawa'
  },
  FI: {
    name: 'FI'
  }
}

export const BoardList = [
  {
    name: "Spot",
    code: "S",
    long_name: "Spot",
  },
  {
    name: "FI",
    code: "FI",
    long_name: "FI",
  },
  {
    name: "OTC",
    code: "O",
    long_name: "OTC",
  },
  {
    name: "Dawa",
    code: "D",
    long_name: "Deliverable",
  },
];

export const PermissionsList = [
  { name: "Client", value: "Client" },
  { name: "Broker-Dealer", value: "Broker-Dealer" },
  { name: "Broker", value: "Broker" },
  { name: "Investor", value: "Investor" },
];

export const CHART_COLORS = [
  "#D71E0E",
  "#FDE3D3",
  "#A0AEC0",
  "#E07471",
  "#E08100",
  "#7E92A7",
  "#3F6A83",
  "#1D925D",
  "#E59695",
  "#DFB300",
];

export const afexLinks = {

  COMX_APPLE_STORE: 'https://apps.apple.com/us/app/comx-by-afex/id1494447284?ls=1',
  COMX_GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.afexnigeria.comx',

  AFEX_WEBSITE: 'https://afexnigeria.com',

  AFEX_TWITTER: 'https://twitter.com/comxbyafex?s=11',
  AFEX_FACEBOOK: 'https://www.facebook.com/comxbyafex/',
  AFEX_INSTAGRAM: 'https://instagram.com/comxbyafex?utm_medium=copy_link',
  AFEX_LINKEDIN: 'https://www.linkedin.com/company/comx-by-afex',

  AFEX_TEL: 'tel:+234700022552339',
  AFEX_CONTACT_US_MAIL: 'mailto:contactus@afexnigeria.com',
  AFEX_SUPPORT_MAIL: 'mailto:support@afexnigeria.com'
}

export const _ADMIN_PAGE_PERMISSIONS = {
  DASHBOARD: "comx_admin_dashboard_overview",

  TRADES: "comx_admin_trade_trades",
  TRADES_REPORT: "comx_admin_trade_trade_report",
  MATCH_ORDERS: "comx_admin_trade_match_orders",
  COMMUNITY_TRADES: "comx_admin_trade_community_trades",
  OTC_CONTRACT: "comx_admin_otc_contracts",
  CLOSING_PRICE: "comx_admin_trade_market_survelliance",
  TRADE: [
    "comx_admin_trade_trades",
    "comx_admin_trade_trade_report",
    "comx_admin_trade_trade_trades",
    "comx_admin_trade_match_orders",
    "comx_admin_trade_community_trades",
    "comx_admin_trade_market_survelliance",
  ],

  CLIENTS_STATS_MGT: "comx_admin_client_statistic_management",
  CLIENTS_SEARCH_MGT: "comx_admin_client_search_management",
  CLIENTS_INCOMPLETE_KYC: "comx_admin_client_incomplete_kyc_management",
  CLIENTS: [
    "comx_admin_client_statistic_management",
    "comx_admin_client_search_management",
    "comx_admin_client_incomplete_kyc_management",
  ],

  CLIENTS_ASSETS_WALLET_BALANCE: "comx_admin_client_assets_wallet_balance",
  CLIENTS_ASSETS_SECURITY_BALANCE: "comx_admin_client_assets_security_balance",
  CLIENT_ASSETS: [
    "comx_admin_client_assets_wallet_balance",
    "comx_admin_client_assets_security_balance",
  ],

  COMMUNITY_STATS: "comx_admin_community_statistics",
  COMMUNITY: ["comx_admin_community", "comx_admin_community_statistics"],

  EDIT_CLIENT_DETAIL: "comx_admin_edit_client_details_log",

  FUNDING: "comx_admin_fund_transfer",
  WITHDRAWAL: "comx_admin_withdrawal",
  DEPOSITS: "comx_admin_deposit",
  FUNDING_AND_PAYMENTS: [
    "comx_admin_fund_transfer",
    "comx_admin_withdrawal",
    "comx_admin_deposit",
  ],

  LOAN_DASHBOARD: "comx_admin_loan_summary_dashboard",
  LIST_OF_OBLIGORS: "comx_admin_list_of_obligors",
  LOAN_REPORT: [
    "comx_admin_loan_summary_dashboard",
    "comx_admin_list_of_obligors",
  ],

  SURVEY: "comx_admin_content_management_survey",
  FAQS: "comx_admin_content_management_mobile_faq",
  ARCHETYPES: "comx_admin_content_management_archetype",
  RATINGS: "comx_admin_content_management_user_rating",
  INTRO_TIPS: "comx_admin_content_management_intro_tips",
  EDEX: "comx_admin_content_management_edex_video",
  NEWS_FEEDS: "comx_admin_content_management_news_feed",
  CONTENT_MANAGEMENT: [
    "comx_admin_content_management_survey",
    "comx_admin_content_management_mobile_faq",
    "comx_admin_content_management_archetype",
    "comx_admin_content_management_user_rating",
    "comx_admin_content_management_intro_tips",
    "comx_admin_content_management_edex_video",
    "comx_admin_content_management_news_feed",
  ],

  PROMOTER_MANAGEMENT: "comx_admin_promoter_management",
  BROKER_MANAGEMENT: "comx_admin_broker_management",
  CLIENT_KYC_VERIFICATION: "comx_admin_client_kyc_verification",
  MARKET_REPORT: "comx_admin_market_report",
  SEND_BLAST_SMS: "comx_admin_send_blast_sms",

  ADMINISTRATION_CLIENT_MANAGEMENT:
    "comx_admin_administration_client_management",
  ADMINISTRATION_ADMIN_MANAGEMENT: "comx_admin_administration_admin_management",
  ADMINISTRATION: [
    "comx_admin_administration_client_management",
    "comx_admin_administration_admin_management",
  ],

  TRACKER_DASHBOARD: "tracker_dashboard",
  AUDIT_LOG: "comx_admin_audit_log",
  ACTIVITY_STREAM: "comx_admin_activity_streams",
  ADMIN_PROFILE: "comx_admin",
};

// export const ADMIN_VIEW_ACCESS = [
//     { name: 'Dashboard Overview', value: ADMIN_PAGE_PERMISSIONS.DASHBOARD, submenu: [] },
//     {
//         name: 'Trades',
//         value: ADMIN_PAGE_PERMISSIONS.TRADE[0],
//         submenu: [
//             { name: 'Trade Report', value: ADMIN_PAGE_PERMISSIONS.TRADES_REPORT },
//             { name: 'Trades', value: ADMIN_PAGE_PERMISSIONS.TRADES },
//             { name: 'Community Trades', value: ADMIN_PAGE_PERMISSIONS.COMMUNITY_TRADES },
//             { name: 'Closing Price', value: ADMIN_PAGE_PERMISSIONS.CLOSING_PRICE }
//         ]
//     },
//     {
//         name: 'Client Assets',
//         value: 'client_assets',
//         submenu: [
//             { name: 'Wallet Balance', value: ADMIN_PAGE_PERMISSIONS.CLIENTS_ASSETS_WALLET_BALANCE },
//             { name: 'Security Balance', value: ADMIN_PAGE_PERMISSIONS.CLIENTS_ASSETS_SECURITY_BALANCE }
//         ]
//     },
//     {
//         name: 'Clients',
//         value: 'comx_admin_client_management',
//         submenu: [
//             { name: 'Client Statistics', value: ADMIN_PAGE_PERMISSIONS.CLIENTS_STATS_MGT },
//             { name: 'Client Search', value: ADMIN_PAGE_PERMISSIONS.CLIENTS_SEARCH_MGT },
//             { name: 'Incomplete KYC', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.CLIENTS_INCOMPLETE_KYC }
//         ]
//     },
//     {
//         name: 'Community',
//         value: ADMIN_PAGE_PERMISSIONS.COMMUNITY[0],
//         submenu: [
//             { name: 'Community Statistics', value: ADMIN_PAGE_PERMISSIONS.COMMUNITY_STATS },
//             { name: 'Community', value: ADMIN_PAGE_PERMISSIONS.COMMUNITY[0] }
//         ]
//     },
//     {
//         name: 'Funding & Payments',
//         value: 'comx_admin_deposit_and_payment',
//         submenu: [
//             { name: 'Deposit Report', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.DEPOSITS },
//             { name: 'Withdrawal Report', value: ADMIN_PAGE_PERMISSIONS.WITHDRAWAL },
//             { name: 'Fund Transfer', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.FUNDING }
//         ]
//     },
//     {
//         name: 'Loan Report',
//         value: ADMIN_PAGE_PERMISSIONS.LOAN_REPORT[0],
//         submenu: [
//             { name: 'Loan Summary', value: ADMIN_PAGE_PERMISSIONS.LOAN_DASHBOARD },
//             { name: 'List Of Obligors', value: ADMIN_PAGE_PERMISSIONS.LIST_OF_OBLIGORS }
//         ]
//     },
//     {
//         name: 'Content Management',
//         value: ADMIN_PAGE_PERMISSIONS.CONTENT_MANAGEMENT[0],
//         submenu: [
//             { name: 'Survey Questions', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.SURVEY },
//             { name: 'FAQs', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.FAQS },
//             { name: 'Archetypes', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.ARCHETYPES },
//             { name: 'Ratings', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.RATINGS },
//             { name: 'Introductory Tips', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.INTRO_TIPS },
//             { name: 'Edex', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.EDEX },
//             { name: 'News Feeds', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.NEWS_FEEDS }
//         ]
//     },
//     { name: 'Promoter Management', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.PROMOTER_MANAGEMENT, submenu: [] },
//     { name: 'Broker Management', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.BROKER_MANAGEMENT, submenu: [] },
//     { name: 'Market Report', value: ADMIN_PAGE_PERMISSIONS.MARKET_REPORT, submenu: [] },
//     { name: 'Client KYC Verification', value: ADMIN_PAGE_PERMISSIONS.CLIENT_KYC_VERIFICATION, submenu: [] },
//     { name: 'Tracker Dashboard', value: ADMIN_PAGE_PERMISSIONS.TRACKER_DASHBOARD, submenu: [] },
//     { name: 'Audit log', value: ADMIN_PAGE_PERMISSIONS.AUDIT_LOG, submenu: [] },
//     { name: 'Send Blast SMS', value: ADMIN_PAGE_PERMISSIONS.SEND_BLAST_SMS, submenu: [] },
//     {
//         name: 'User Administration',
//         value: ADMIN_PAGE_PERMISSIONS.ADMINISTRATION[0],
//         submenu: [
//             { name: 'Clients Management', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.ADMINISTRATION_CLIENT_MANAGEMENT },
//             { name: 'Admin Management', hasEdit: true, value: ADMIN_PAGE_PERMISSIONS.ADMINISTRATION_ADMIN_MANAGEMENT }
//         ]
//     }
// ]

export const ADMIN_ACCESS_TITLES = [
  { name: "Key Account Manager", value: "key_account_manager_admin" },
  { name: "Collateral Manager", value: "collateral_managers_admin" },
  { name: "Financier", value: "financiers_admin" },
  { name: "ComX Retail", value: "retail_sales_admin" },
  { name: "ComX Institutional", value: "institutional_admin" },
  {
    name: "Strategy and Corporate Finance",
    value: "strategy_and_corporate_finance_admin",
  },
  { name: "Market Controller", value: "exchange_market_controller" },
  { name: "Customer Support", value: "customer_support_admin" },
  { name: "Finance Team", value: "finance_team_admin" },
  { name: "Product Management", value: "product_management" },
  { name: "Software Engineers", value: "software_engineers" },
  { name: "Data Analysts", value: "data_analysts" },
  { name: "Others", value: "others" },
];

export const ADMIN_CLIENT_VIEW_ACCESS = [
  { name: "all Client Types", value: "all_client_types" },
  { name: "Investors", value: "Investor" },
  { name: "Corporate", value: "Corporate" },
  { name: "Promoters", value: "Promoter" },
  { name: "Brokers", value: "Broker" },
  { name: "Broker-Dealers", value: "Broker-Dealer" },
  { name: "Arrangers", value: "Arranger" },
  { name: "Custodians", value: "Custodian" },
  { name: "Selected Clients", value: "selected_clients" },
];

export const UNIQUE_URLS = [
  "funding-list",
  "incomplete-kyc-client",
  "admin-all-clients-list",
  "audit-request-status-log",
  "activity-streams",
  "admin/client-kyc-verification-list",
  "admin-client-wallet-balanace",
  "admin/security-balance",
  "deposit-list",
  "admin-client-wallet-log",
  "rade-market-survelliance",
  "deactivated-admin-users",
  "admin/client-search/withdrawal-list",
  "trade-report-view",
  "admin/matched-orders-list",
  "admin-users-list",
  "audit-logs",
  "admin-client-profile",
  "withdrawal-list",
  "admin/edit-client-details-list",
  "admin/otc_contract_list",
  "admin/otc_contract/delivery-log/list",
  "admin/delivery-logs/list",
  "admin/loan-balance-history-list"
];

export const DATE_RANGE_FORMAT = "YYYY-MM-DD";

export const DEFAULT_SELECTION_RANGE = {
  startDate: new Date(),
  endDate: new Date(),
  date_from: "",
  date_to: "",
  showAll: true,
};

export const PAYMENT_CHANNELS = [
  { name: "Web", value: "Web" },
  { name: "Android", value: "Android" },
  { name: "Flutterwave", value: "Flutterwave" },
  { name: "IOS", value: "IOS" },
  { name: "Manual", value: "Manual" },
  { name: "WebHook", value: "WebHook" },
];

export const SMS_BLAST_IDS = [
  { name: "COMX", value: "COMX" },
  { name: "AFEX NG", value: "AFEX NG" },
  { name: "AFEX", value: "AFEX" },
];

export const PAGINATION_SIZE_PER_PAGE = 50;
export const PAGINATION_SIZE_PER_PAGE2 = 100;

export const CURRENCY = {
  NAIRA: "₦",
  DOLLAR: "$",
  KSH: "Ksh",
};

export const MAX_FILE_SIZE = {
  text: "1MB",
  value: 1000000,
};

export const UPLOAD_FILE_TYPES = {
  img_doc: ".jpg,.jpeg,.png,.pdf,.jpg,.jpeg,.png",
  img: ".jpg,.jpeg,.png",
  doc: ".pdf,.doc",
};

export const _TransactionTypes = {
  Lien: 'Lien',
  Withdrawal: 'Withdrawal',
  Credit: 'Credit',
  Debit: 'Debit',
  UnLienDebit: 'UnLien-Debit',
  UnLien: 'UnLien',
  LienCredit: 'Lien-Credit',
}