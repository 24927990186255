import { FC, ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'

type Props = { children: ReactNode }

const Layout: FC<Props> = ({ children }) => {

    const [expanded, setExpanded] = useState(false)
    const location = useLocation()

    const toggleSidebar = () => setExpanded(prev => !prev)

    useEffect(() => {
        setExpanded(false)
    }, [location])

    return (
        <div className='h-screen'>
            <Header expanded={expanded} toggleSidebar={toggleSidebar} />
            <div>
                <Sidebar expanded={expanded} toggleSidebar={toggleSidebar} />
                <div className='lg:ml-[13%] 2xl:ml-[11%] mt-16 bg-[#F5F5F5] md:p-[1%] min-h-screen overflow-scroll layout-parent'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout