import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useAuth } from '../contexts/auth'
import manager from '../utils/encryption'
import { handleDateRange } from '../utils/functions'
import { LOCAL_STORAGE_TOKEN, UNIQUE_URLS } from '../utils/variables'
import { useIdleTimerContext } from 'react-idle-timer'

const { REACT_APP_LIVE_URL: BASE_URL } = process.env

export const liveAxios = axios.create({
  baseURL: `${BASE_URL}/api/`,
  withCredentials: true,
});

export const getter = async (url: string) => {
  const response: any = url && await liveAxios.get(url, {
    headers: { Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}` }
  })
  return response
}

export const _getter = async (url: string) => {
  const response: any = url && await liveAxios.get(url, {
    headers: { Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}` }
  })
  return response?.data
}

type FetcherType = {
  uri: string,
  query?: string,
  canPaginate?: Boolean,
  dateRange?: any,
  canSearch?: Boolean,
  canExport?: Boolean,
  currency?: any
}

export const usePullData = ({ uri, query, canPaginate, dateRange, canSearch, canExport, currency }: FetcherType) => {

  const [activePage, setActivePage] = useState(1)
  const [search, setSearch] = useState('')

  let url = uri

  if (uri) {
    if (query) url += `${querySymbol(url)}${query}`
    if (canPaginate) {
      if (canExport && !dateRange.showAll) url += `${querySymbol(url)}option=export`
      else url += `${querySymbol(url)}page=${activePage}`
    }
    if (dateRange && !dateRange.showAll) url += `${querySymbol(url)}${handleDateRange(dateRange)}`
    if (currency) url += `${querySymbol(url)}currency_code=${currency.filter.currency_code}`
    if (canSearch) url += `${querySymbol(url)}search=${search}`
  }

  function querySymbol(url: string) {
    return url?.includes('?') ? '&' : '?'
  }

  useEffect(() => {
    setActivePage(1)
  }, [search, query])

  const { action } = useAuth()
  return {
    ...useQuery(url.split('/'), () =>
      getter(url).then((response) => {
        const result = response.data
        if (Number(result.responseCode) === 103) {
          // notification.error({ message: 'Error', description: result?.message || 'Error occured fetching data' })
          return UNIQUE_URLS.find(u => url.includes(u)) ? { data: [] } : []
        } else {
          manager.decrypt(result.data)
          return UNIQUE_URLS.find(u => url.includes(u)) ? result : result?.data
        }
      }).catch(error => error?.response?.status === Number(401) && action('signout'))
    ),
    invalidateQuery: url.split('/'),
    activePage,
    setActivePage,
    search,
    setSearch
  }
}

export function useRecursiveFetch({ uri, query: queries, dateRange, currency, search, startExport }: any) {

  const idleTimer = useIdleTimerContext()

  let url = uri

  if (uri) {
    if (queries) url += `${querySymbol(url)}${queries}`
    if (dateRange && !dateRange.showAll) url += `${querySymbol(url)}${handleDateRange(dateRange)}`
    if (currency) url += `${querySymbol(url)}currency_code=${currency.filter.currency_code}`
    if (search) url += `${querySymbol(url)}search=${search}`
  }

  function querySymbol(url: string) {
    return url?.includes('?') ? '&' : '?'
  }

  const {
    data: fetchedData, isFetching, hasNextPage, fetchNextPage, ...restProps
  } = useInfiniteQuery({
    queryKey: [url?.split('/')],
    keepPreviousData: true,
    optimisticResults: false,
    refetchOnWindowFocus: false,
    enabled: startExport,
    cacheTime: 1 * 60 * 1000,
    staleTime: Infinity,
    queryFn: async ({ pageParam = 1 }) => {
      const query = querySymbol(url)
      return _getter(`${url}${query}page=${pageParam}`).then((response) => {
        const result = response
        if (Number(result.responseCode) === 103) {
          return UNIQUE_URLS.find(u => url.includes(u)) ? { data: [] } : []
        } else {
          manager.decrypt(result.data)
          return UNIQUE_URLS.find(u => url.includes(u)) ? result : result?.data
        }
      }).catch(error => console.log(error))
    },
    getNextPageParam: (lastPage: any, pages) => lastPage.next ? pages.length + 1 : null,
  })

  const data =
    fetchedData?.pages?.reduce((acc, curr) => {
      const { data, ...rest }: any = { ...curr };
      return Object.assign(acc, rest, {
        data: Array.isArray(data) ? (acc["data"] ?? []).concat(data) : data,
      });
    }, {}) ?? fetchedData?.pages?.at(0);

  useEffect(() => {
    if (!isFetching) {
      if (hasNextPage) {
        idleTimer.reset()
        fetchNextPage()
      }
    }
  }, [isFetching]);

  const queryResult = useMemo(() => {
    return {
      ...restProps,
      isLoading: isFetching || hasNextPage,
      data,
    };
  }, [data, isFetching]);

  return queryResult;
}