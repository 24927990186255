import { createContext, useContext } from "react";
import Loading from "../components/General/Utilities/Loading";
import { usePullData } from "../hooks/useFetch";
import { _ADMIN_PAGE_PERMISSIONS } from "../utils/variables";

const permissionContext = createContext({
  ADMIN_PAGE_PERMISSIONS: _ADMIN_PAGE_PERMISSIONS,
});

const PermissionProvider = ({ children }: any) => {
  const { data, isLoading } = usePullData({ uri: "admin/permissions-list" });

  if (isLoading) return <Loading />;

  const ADMIN_PAGE_PERMISSIONS = {
    DASHBOARD: data?.DASHBOARD,

    TRADES: data?.TRADES,
    TRADES_REPORT: data?.TRADES_REPORT,
    MATCH_ORDERS: data?.MATCH_ORDERS,
    COMMUNITY_TRADES: data?.COMMUNITY_TRADES,
    CLOSING_PRICE: data?.CLOSING_PRICE,
    OTC_CONTRACT: data?.OTC_CONTRACT,
    TRADE: [
      data?.TRADES,
      data?.TRADES_REPORT,
      data?.MATCH_ORDERS,
      data?.COMMUNITY_TRADES,
      data?.OTC_CONTRACT,
      data?.CLOSING_PRICE,
    ],

    CLIENTS_STATS_MGT: data?.CLIENTS_STATS_MGT,
    CLIENTS_SEARCH_MGT: data?.CLIENTS_SEARCH_MGT,
    CLIENTS_INCOMPLETE_KYC: data?.CLIENTS_INCOMPLETE_KYC,
    CLIENTS: [
      data?.CLIENTS_STATS_MGT,
      data?.CLIENTS_SEARCH_MGT,
      data?.CLIENTS_INCOMPLETE_KYC,
    ],

    CLIENTS_ASSETS_WALLET_BALANCE: data?.CLIENTS_ASSETS_WALLET_BALANCE,
    CLIENTS_ASSETS_SECURITY_BALANCE: data?.CLIENTS_ASSETS_SECURITY_BALANCE,
    CLIENT_ASSETS: [
      data?.CLIENTS_ASSETS_WALLET_BALANCE,
      data?.CLIENTS_ASSETS_SECURITY_BALANCE,
    ],

    COMMUNITY_STATS: data?.COMMUNITY_STATS,
    COMMUNITY: [data?.COMMUNITY, data?.COMMUNITY_STATS],

    EDIT_CLIENT_DETAIL: data?.EDIT_CLIENT_DETAIL,

    FUNDING: data?.FUNDING,
    WITHDRAWAL: data?.WITHDRAWAL,
    DEPOSITS: data?.DEPOSITS,
    FUNDING_AND_PAYMENTS: [data?.FUNDING, data?.WITHDRAWAL, data?.DEPOSITS],

    LOAN_DASHBOARD: data?.LOAN_DASHBOARD,
    LIST_OF_OBLIGORS: data?.LIST_OF_OBLIGORS,
    LOAN_REPORT: [data?.LOAN_DASHBOARD, data?.LIST_OF_OBLIGORS],

    SURVEY: data?.SURVEY,
    FAQS: data?.FAQS,
    ARCHETYPES: data?.ARCHETYPES,
    RATINGS: data?.RATINGS,
    INTRO_TIPS: data?.INTRO_TIPS,
    EDEX: data?.EDEX,
    NEWS_FEEDS: data?.NEWS_FEEDS,
    CONTENT_MANAGEMENT: [
      data?.SURVEY,
      data?.FAQS,
      data?.ARCHETYPES,
      data?.RATINGS,
      data?.INTRO_TIPS,
      data?.EDEX,
      data?.NEWS_FEEDS,
    ],

    PROMOTER_MANAGEMENT: data?.PROMOTER_MANAGEMENT,
    BROKER_MANAGEMENT: data?.BROKER_MANAGEMENT,
    CLIENT_KYC_VERIFICATION: data?.CLIENT_KYC_VERIFICATION,
    MARKET_REPORT: data?.MARKET_REPORT,
    SEND_BLAST_SMS: data?.SEND_BLAST_SMS,

    ADMINISTRATION_CLIENT_MANAGEMENT: data?.ADMINISTRATION_CLIENT_MANAGEMENT,
    ADMINISTRATION_ADMIN_MANAGEMENT: data?.ADMINISTRATION_ADMIN_MANAGEMENT,
    ADMINISTRATION: [
      data?.ADMINISTRATION_CLIENT_MANAGEMENT,
      data?.ADMINISTRATION_CLIENT_MANAGEMENT,
    ],

    TRACKER_DASHBOARD: data?.TRACKER_DASHBOARD,
    AUDIT_LOG: data?.AUDIT_LOG,
    ACTIVITY_STREAM: data?.ACTIVITY_STREAM,
    ADMIN_PROFILE: "comx_admin",
  };

  return (
    <permissionContext.Provider value={{ ADMIN_PAGE_PERMISSIONS }}>
      {children}
    </permissionContext.Provider>
  );
};

export default PermissionProvider;

export const usePermissions = () => useContext(permissionContext);
