import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "../components/General/Layout";
import Loading from "../components/General/Utilities/Loading";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
} from "chart.js";
import useFetchAdminData from "../hooks/utility/useFetchAdminData";
import { usePermissions } from "../contexts/permissions";
import { notification } from "antd";
import { IdleTimerProvider } from "react-idle-timer";
import { useAuth } from "../contexts/auth";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  BarElement,
  Tooltip
);

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Trades = lazy(() => import("../pages/Trades"));
const Clients = lazy(() => import("../pages/Clients"));
const ClientAssets = lazy(() => import("../pages/ClientAssets"));
const ClientDetailsUpdate = lazy(() => import("../pages/ClientDetailsUpdate"));
const CorporateDetailsUpdate = lazy(
  () => import("../pages/CorporateDetailsUpdate")
);
const Community = lazy(() => import("../pages/Community"));
const Funding = lazy(() => import("../pages/Funding"));
const LoanReport = lazy(() => import("../pages/LoanReport"));
const ContentManagement = lazy(() => import("../pages/ContentManagement"));
const PromoterManagement = lazy(() => import("../pages/PromoterManagement"));
const BrokerManagement = lazy(() => import("../pages/BrokerManagement"));
const ClientKYCVerification = lazy(
  () => import("../pages/ClientKYCVerification")
);
const MarketReport = lazy(() => import("../pages/MarketReport"));
const SendSMSBlast = lazy(() => import("../pages/SendSMSBlast"));
const Administration = lazy(() => import("../pages/Administration"));
const TrackerDashboard = lazy(() => import("../pages/TrackerDashboard"));
const AuditLog = lazy(() => import("../pages/AuditLog"));
const ActivityStream = lazy(() => import("../pages/ActivityStream"));
const AdminProfile = lazy(() => import("../pages/AdminProfile"));

const Authenticated = () => {
  const { ADMIN_PAGE_PERMISSIONS } = usePermissions();
  const { action } = useAuth();

  const navigate = useNavigate();

  const localStorage = useFetchAdminData();

  let idleTimeout: any;

  const onIdle = () => {
    notification.warn({
      description:
        "You will be logged out in 10 seconds if you do not perform any activity",
      message: "You have been inactive for some time",
    });
    idleTimeout = setTimeout(() => {
      performLogout();
    }, 10000);
  };

  function performLogout() {
    action("signout");
    navigate("/");
  }

  function onActive() {
    clearTimeout(idleTimeout);
  }

  const routes = [
    {
      path: "/*",
      element: <Dashboard />,
      value: ADMIN_PAGE_PERMISSIONS.DASHBOARD,
      useablePath: "/",
    },
    {
      path: "trades",
      element: <Trades />,
      value: ADMIN_PAGE_PERMISSIONS.TRADE,
    },
    {
      path: "clients",
      element: <Clients />,
      value: ADMIN_PAGE_PERMISSIONS.CLIENTS,
    },
    {
      path: "client_assets",
      element: <ClientAssets />,
      value: ADMIN_PAGE_PERMISSIONS.CLIENT_ASSETS,
    },
    {
      path: "client_details_update",
      element: <ClientDetailsUpdate />,
      value: ADMIN_PAGE_PERMISSIONS.EDIT_CLIENT_DETAIL,
    },
    {
      path: "corporate_details_update",
      element: <CorporateDetailsUpdate />,
      value: ADMIN_PAGE_PERMISSIONS.EDIT_CLIENT_DETAIL,
    },
    {
      path: "loan_report",
      element: <LoanReport />,
      value: ADMIN_PAGE_PERMISSIONS.LOAN_REPORT,
    },
    {
      path: "community",
      element: <Community />,
      value: ADMIN_PAGE_PERMISSIONS.COMMUNITY,
    },
    {
      path: "funding",
      element: <Funding />,
      value: ADMIN_PAGE_PERMISSIONS.FUNDING_AND_PAYMENTS,
    },
    {
      path: "content_management",
      element: <ContentManagement />,
      value: ADMIN_PAGE_PERMISSIONS.CONTENT_MANAGEMENT,
    },
    {
      path: "promoter_management",
      element: <PromoterManagement />,
      value: ADMIN_PAGE_PERMISSIONS.PROMOTER_MANAGEMENT,
    },
    {
      path: "broker_management",
      element: <BrokerManagement />,
      value: ADMIN_PAGE_PERMISSIONS.BROKER_MANAGEMENT,
    },
    {
      path: "client_kyc_verification",
      element: <ClientKYCVerification />,
      value: ADMIN_PAGE_PERMISSIONS.CLIENT_KYC_VERIFICATION,
    },
    {
      path: "market_report",
      element: <MarketReport />,
      value: ADMIN_PAGE_PERMISSIONS.MARKET_REPORT,
    },
    {
      path: "send_blast_sms",
      element: <SendSMSBlast />,
      value: ADMIN_PAGE_PERMISSIONS.SEND_BLAST_SMS,
    },
    {
      path: "administration",
      element: <Administration />,
      value: ADMIN_PAGE_PERMISSIONS.ADMINISTRATION,
    },
    {
      path: "tracker_dashboard/*",
      element: <TrackerDashboard />,
      value: ADMIN_PAGE_PERMISSIONS.TRACKER_DASHBOARD,
      useablePath: "tracker_dashboard",
    },
    {
      path: "audit_log",
      element: <AuditLog />,
      value: ADMIN_PAGE_PERMISSIONS.AUDIT_LOG,
    },
    {
      path: "activity_stream",
      element: <ActivityStream />,
      value: ADMIN_PAGE_PERMISSIONS.ACTIVITY_STREAM,
    },
    {
      path: "admin_profile",
      element: <AdminProfile />,
      value: ADMIN_PAGE_PERMISSIONS.ADMIN_PROFILE,
    },
  ];

  const filteredRoutes = routes.filter((route) => {
    return localStorage.permissions.find((permission: any) => {
      return Array.isArray(route.value)
        ? route.value.find((item) => permission === `can_view_${item}`)
        : permission.includes(route.value);
    });
  });

  return (
    <IdleTimerProvider
      timeout={5 * 1000 * 60} // 5 minutes
      onIdle={onIdle}
      onActive={onActive}
    >
      <Layout>
        <Suspense fallback={<Loading />}>
          <Routes>
            {filteredRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route path="*" element={<Redirect routes={filteredRoutes} />} />
          </Routes>
        </Suspense>
      </Layout>
    </IdleTimerProvider>
  );
};

export default Authenticated;

const Redirect = ({ routes }: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(routes[0].useablePath || routes[0].path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
