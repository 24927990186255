// import { WidgetController } from 'afex-app-widget';
import { CloseCircle, HambergerMenu } from 'iconsax-react';
import { Link } from 'react-router-dom';

import useFetchAdminData from '../../../hooks/utility/useFetchAdminData';

// const { REACT_APP_SSO_WIDET_KEY } = process.env;

const Header = ({
  expanded,
  toggleSidebar,
}: {
  expanded: boolean;
  toggleSidebar: () => void;
}) => {
  const adminData = useFetchAdminData();

  return (
    <nav className='fixed top-0 flex items-center justify-between h-16 w-full bg-white z-20 border-b-[1px] border-[#E8ECEF]'>
      <Link to='/'>
        <img
          className='ml-5 md:ml-5 h-16'
          src='/images/comx_logo.png'
          alt='comx admin'
        />
      </Link>
      <span
        onClick={toggleSidebar}
        className='pr-4 lg:hidden cursor-pointer'>
        {expanded ? (
          <CloseCircle className='h-6 w-6 text-red-400' />
        ) : (
          <HambergerMenu className='h-6 w-6' />
        )}
      </span>

      <div className='flex items-center gap-4 border-l border-[#E8ECEF] h-full pl-4'>
        {/* <WidgetController
          apiKey={REACT_APP_SSO_WIDET_KEY || ''}
          email={adminData.email}
          position='right'
        /> */}
        <Link
          to='/admin_profile'
          className='hidden lg:flex items-center gap-3 mr-5 h-full'>
          <div className='h-8 w-8 bg-[#F5F5F5] text-[#C81107] border-[1px] border-[#C81107] border-opacity-25 text-xs rounded-full flex items-center justify-center'>
            <span className='w-fit uppercase'>{`${
              adminData?.first_name?.[0] || ''
            }${adminData?.last_name?.[0] || ''}`}</span>
          </div>
          <h4 className='text-[#54565B] font-normal text-base'>
            Welcome,{' '}
            <span className='font-medium'>{adminData?.first_name}!</span>
          </h4>
        </Link>
      </div>
    </nav>
  );
};

export default Header;
