import { QueryClient, QueryClientProvider } from 'react-query'
import './App.scss'
import { useAuth } from './contexts/auth'
import PermissionProvider from './contexts/permissions'
import Authenticated from './modes/Authenticated'
import UnAuthenticated from './modes/UnAuthenticated'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
})

const App = () => {

  const { auth } = useAuth()

  return (
    <QueryClientProvider client={queryClient}>
      {auth ?
        <PermissionProvider>
          <Authenticated />
        </PermissionProvider> :
        <UnAuthenticated />
      }
    </QueryClientProvider>
  )
}

export default App